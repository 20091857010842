import { UPDATE_USER_MUTATION } from '../graphql/user-mutations';
import CookieService from '../services/cookie.service';
import { apolloClient } from '../services/graphql.service';
import { getRandomTestGroup } from './ssr';

export const getRandomCookieTest = (cookieName, expires = 90, prob) => {
    const myProb = isNaN(prob) ? 50 : prob;
    let valCookie = CookieService.get(cookieName);
    if (valCookie === null) {
        const newGroup = getRandomTestGroup(myProb) ? 'NEW' : 'OLD';
        CookieService.set(cookieName, newGroup, { expires });
        valCookie = newGroup;
    }
    return valCookie === 'NEW';
};

export const getAndUpdateABTest = async ({ cookie, forceGroup, user = {}, userTestGroup, prob }) => {
    const result = typeof forceGroup == 'undefined' ? getRandomCookieTest(cookie, 90, prob) : forceGroup;
    let testResult = user.id ? user.testGroups[userTestGroup] : result;

    try {
        if (user.id && user.testGroups[userTestGroup] === null) {
            testResult = result;
            await apolloClient.mutate({
                mutation: UPDATE_USER_MUTATION,
                variables: {
                    id: user.id,
                    testGroups: [userTestGroup, `${testResult}`],
                },
            });
        }
    } catch (error) {
        console.warn(error);
    }

    return testResult;
};

export const getTestWithProb = async ({ cookie, getProbTest, prob, user, userTestGroup }) => {
    const testResult = user.id && user.testGroups[userTestGroup];

    if (testResult !== null) {
        return testResult;
    } else {
        try {
            let finalProb = prob;

            if (!finalProb) {
                const { data } = await getProbTest({ variables: { tests: [userTestGroup] } });
                finalProb = data.probabilities?.length > 0 ? data.probabilities[0].probability : 0;
            }

            if (finalProb > 0) {
                const isTestTemp = await getAndUpdateABTest({ cookie, user, userTestGroup, prob: finalProb });
                return isTestTemp;
            }
        } catch (error) {
            console.warn(error);
        }
    }

    return null;
};
