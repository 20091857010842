export const AUTH_TESTS = {
    TOGGLE_FILTERS: 'TOGGLE_FILTERS',
    TOGGLE_SEARCH: 'TOGGLE_SEARCH',
    TOGGLE_WATER: 'TOGGLE_WATER',
    TOGGLE_INDOOR: 'TOGGLE_INDOOR',
    TOGGLE_FENCED: 'TOGGLE_FENCED',
    TOGGLE_NODOGS: 'TOGGLE_NODOGS',
    TOGGLE_HIKING: 'TOGGLE_HIKING',
    TOGGLE_TOPSPOTS: 'TOGGLE_TOPSPOTS',
    TOGGLE_IN10MILES: 'TOGGLE_IN10MILES',
    TOGGLE_MINHALFACR: 'TOGGLE_MINHALFACR',
};
