import { SF_FENCE_OPTIONS } from '../../consts/search-filter.const';

export function getFiltersForServer(filters) {
    if (!filters) {
        return {};
    }

    let tmp = {};

    if (filters.availability && filters.availability.startDate) {
        tmp = { ...filters.availability };
    }

    tmp.minimumSize = filters.size.min || undefined;
    tmp.maximumSize = filters.size.max || undefined;
    tmp.maximumPrice = filters.price || undefined;
    tmp.enclosureType = filters.enclosureType || undefined;
    tmp.fenceHeight = filters.fenceHeight || undefined;
    tmp.dogsPresent = filters.dogsPresent ? undefined : 'NO';
    tmp.peoplePresent = filters.peoplePresent ? undefined : 'NO';
    tmp.domesticAnimalsPresent = filters.domesticAnimalsPresent ? undefined : 'NO';
    tmp.privateEntry = filters.privateEntry ? 'YES' : undefined;
    tmp.tags = filters.tags?.length ? filters.tags : undefined;

    return tmp;
}

export function getSearchFiltersForServer(filters) {
    if (!filters) {
        return {};
    }

    let tmp = {};
    tmp.availableDates = filters.availableDates || undefined;
    tmp.availableTimes = filters.availableTimes || undefined;
    tmp.enclosureType = filters.enclosureType || undefined;
    tmp.fenceHeight = filters.fencing || SF_FENCE_OPTIONS[0].value || undefined;
    tmp.minimumSize = filters.minimumSize || undefined;
    tmp.distance = filters.distance || undefined;
    tmp.dogsPresent = filters.dogsPresent ? 'NO' : undefined;
    tmp.peoplePresent = filters.peoplePresent ? 'NO' : undefined;
    tmp.domesticAnimalsPresent = filters.domesticAnimalsPresent ? 'NO' : undefined;
    tmp.topSpots = filters.topSpots || undefined;
    tmp.newSpots = filters.newSpots || undefined;
    tmp.cleanerSpots = filters.cleanerSpots || undefined;
    tmp.amenities = filters.amenities?.length > 0 ? filters.amenities : undefined;
    tmp.maximumDogsAllowed = filters.maximumDogsAllowed || undefined;
    tmp.minimumPrice = filters.minimumPrice || undefined;
    tmp.maximumPrice = filters.maximumPrice || undefined;
    tmp.sortBy = filters.sortBy || undefined;
    tmp.showPublicSpots = filters.showPublicSpots || undefined;
    tmp.showPrivateSpots = filters.showPrivateSpots || undefined;

    return tmp;
}
